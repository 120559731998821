<template>
  <div class="welcome-phisical-tco-container">
    <WelcomeMessage
      :textPath="'tco.becomeTco.welcomePagePhisical.welcomeBanner'"
    />
  </div>
</template>
<script>
import WelcomeMessage from "@/components/cardOro/WelcomeMessage.vue";

export default {
  name: "WelcomePhisicalTco",
  components: { WelcomeMessage },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {}
};
</script>
